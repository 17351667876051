<template>
    <div>
   
     <!-- 
        Dependent
    -->            
    <div class="mx-auto pb-10">
        <v-row>

            <!-- First Name  -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.firstName != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.firstName" 
                                    :items="selectedColumns"
                                    :item-text="firstName"
                                    :item-value="firstName"
                                    label="First Name"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.firstName); member.firstName = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.firstName == ''">
                        <span class="d-flex">
                            <v-select
                                id="dependentFirstName-text"
                                ref="dependentFirstName"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.firstName" 
                                :rules="firstNameMessage" 
                                :items="mappingList"
                                item-text="firstName"
                                item-value="firstName" 
                                @input="handleSelect(`${member.firstName}`, `${getTitle} First Name`)"
                                label="First Name"
                                required
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
                        

            <!-- Last Name  -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.lastName != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.lastName" 
                                    :items="selectedColumns"
                                    :item-text="lastName"
                                    :item-value="lastName"
                                    label="Last Name"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.lastName); member.lastName = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.lastName == ''">
                        <span class="d-flex">
                            <v-select
                                id="dependentLastName-text"
                                ref="dependentLastName"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.lastName" 
                                :rules="lastNameMessage" 
                                :items="mappingList"
                                item-text="lastName"
                                item-value="lastName" 
                                @input="handleSelect(`${member.lastName}`, `${getTitle} Last Name`)"
                                label="Last Name"
                                required
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>

            <!-- DOB  -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.dateOfBirth != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.dateOfBirth" 
                                    :items="selectedColumns"
                                    :item-text="dateOfBirth"
                                    :item-value="dateOfBirth"
                                    label="Date of Birth"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.dateOfBirth); member.dateOfBirth = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.dateOfBirth == ''">
                        <span class="d-flex">
                            <v-select
                                id="dependentdateOfBirth-text"
                                ref="dependentdateOfBirth"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.dateOfBirth" 
                                :rules="dobMessage" 
                                :items="mappingList"
                                item-text="dateOfBirth"
                                item-value="dateOfBirth" 
                                @input="handleSelect(`${member.dateOfBirth}`, `${getTitle} Date of Birth`)"
                                label="Date of Birth"
                                required
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>

            <!-- Gender  -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.gender != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.gender" 
                                    :items="selectedColumns"
                                    :item-text="gender"
                                    :item-value="gender"
                                    label="Gender"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.gender); member.gender = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.gender == ''">
                        <span class="d-flex">
                            <v-select
                                id="dependentGender-text"
                                ref="dependentGender"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.gender" 
                                :items="mappingList"
                                item-text="gender"
                                item-value="gender" 
                                @input="handleSelect(`${member.gender}`, `${getTitle} Gender`)"
                                label="Gender"
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            
            <!-- Email  -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.email != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.email" 
                                    :items="selectedColumns"
                                    :item-text="email"
                                    :item-value="email"
                                    label="Email"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.email); member.email = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.email == ''">
                        <span class="d-flex">
                            <v-select
                                id="dependentEmail-text"
                                ref="dependentEmail"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.email" 
                                :rules="isEmailRequired ? emailMessage : []" 
                                :items="mappingList"
                                item-text="email"
                                item-value="email" 
                                @input="handleSelect(`${member.email}`, `${getTitle} Email`)"
                                label="Email"
                                :required="isEmailRequired"
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>

            <!-- Phone Number  -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.phoneNumber != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.phoneNumber" 
                                    :items="selectedColumns"
                                    :item-text="phoneNumber"
                                    :item-value="phoneNumber"
                                    label="Phone Number"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.phoneNumber); member.phoneNumber = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.phoneNumber == ''">
                        <span class="d-flex">
                            <v-select
                                id="dependentPhoneNumber-text"
                                ref="dependentPhoneNumber"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.phoneNumber" 
                                :items="mappingList"
                                item-text="emaphoneNumberil"
                                item-value="phoneNumber" 
                                @input="handleSelect(`${member.phoneNumber}`, `${getTitle} Phone Number`)"
                                label="Phone Number"
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>    

            <!-- Language -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.language != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.language" 
                                    :items="selectedColumns"
                                    :item-text="language"
                                    :item-value="language"
                                    label="Language"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.language); member.language = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.language == ''">
                        <span class="d-flex">
                            <v-select
                                id="dependentLanguage-text"
                                ref="dependentLanguage"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.language" 
                                :items="mappingList"
                                item-text="language"
                                item-value="language" 
                                @input="handleSelect(`${member.language}`, `${getTitle} Language`)"
                                label="Language"
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col> 

            <!-- External ID -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.externalID != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.externalID" 
                                    :items="selectedColumns"
                                    :item-text="externalID"
                                    :item-value="externalID"
                                    label="External ID"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.externalID); member.externalID = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.externalID == ''">
                        <span class="d-flex">
                            <v-select
                                id="employeeExternalID-text"
                                ref="employeeExternalID"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.externalID" 
                                :items="mappingList"
                                item-text="externalID"
                                item-value="externalID" 
                                @input="handleSelect(`${member.externalID}`, 'External ID')"
                                label="External ID"
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>    
        
    </div>

</template>
<script>
import { useEmployerStore } from "../../../stores/employer";

export default {
    name: "DependentMapping",
    props: ["member", "memberType", "mappingList", "selectedColumns", "activeColumns", "index", "useFileHeaders"],
    setup() {
        const employerStore = useEmployerStore();
        return { employerStore };
    },
    data(){
        return {
            mappingFile: null,
            firstNameMessage:  [
                v => !!v || 'First Name is Required'
            ],
            lastNameMessage:  [
                v => !!v || 'Last Name is Required'
            ],
            dobMessage:  [
                v => !!v || 'Date of Birth is Required'
            ],
            emailMessage:  [
                v => !!v || 'Email is Required'
            ],
            emaillValidator(v) {                    
                    if(this.isEmailRequired){
                        if(v.length == 0 || v == ""){
                            return ['Email is Required'];
                        }
                    }
                    return [];
            }
        }
    },
    mounted() {

    },
    computed: {
        isEmailRequired: {
            get(){
                return this.memberType == 'spouse' || this.memberType == 'adult';
            }
        },
        getTitle: {
            get(){
                if(this.memberType == 'spouse')
                    return 'Spouse ';
                else if(this.memberType == 'adult')
                    return 'Adult ' + (this.index + 1) + ' ';
                else
                    return 'Child ' + (this.index + 1) + ' ';
            }
        }
    },
    methods: {
        handleSelect(event, mapping) {
            this.mappingList.splice(this.mappingList.indexOf(event), 1);            
            let selectedColumn = this.activeColumns.filter((item) => {
                                    return item.columnName == event;
                                });

            if(selectedColumn.length == 0){
                this.activeColumns.push({
                    columnName: event,
                    mapping: mapping
                });
            }
        },

        handleClearSelection: function(value){
            let mapItem = this.mappingList.find(x => x == value);
            if(!mapItem){
                this.mappingList.push(value);
            }

            this.activeColumns.splice(this.activeColumns.findIndex(x => x.columnName == value), 1);
        }    
    }
}
</script>
